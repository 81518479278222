<template>
  <div class="flex items-center mt-2">
    <star :positive="true" v-for="index in this.rating" :key="index + '_enabled'" @click="$emit('rated', index)"></star>
    <star :positive="false" v-for="index in this.max - this.rating" :key="index + '_disabled'"></star>
  </div>
</template>

<script>
import Star from './Star.vue';
export default {
  name: 'RatingBar',
  props: {
    rating: { default: 0, type: Number },
    max: { default: 5, type: Number },
  },
  components: {
    star: Star,
  },
};
</script>
