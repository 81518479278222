<template>
  <svg class="mx-1 w-6 h-6 fill-current" v-bind:class="{ 'text-green-400': positive, 'text-gray-400': !positive }" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z" />
  </svg>
</template>

<script>
export default {
  name: 'Star',
  props: {
    positive: Boolean,
  },
};
</script>
