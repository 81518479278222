<template>
  <div class="user-profile public">
    <div class="container mx-auto" style="z-index: 400">
      <div class="flex overflow-hidden sp-profile-wrap">
        <div id="main-content" class="h-full w-full relative overflow-y-auto">
          <main>
            <div class="w-full grid grid-cols-12 gap-4 pb-6">
              <div class="col-span-8 left">
                <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 provider-info">
                  <div class="w-full grid grid-cols-12 gap-4 pb-6">
                    <div class="rounded-lg p-4 sm:p-6 col-span-8">
                      <div class="avatar fle items-center flex-col">
                        <div class="relative rounded-full text-xl text-white">
                          <img :src="image" class="avatar rounded-full user-img" />
                        </div>
                      </div>
                      <h1 class="pt-4">{{ name }}</h1>
                      <h4>{{ tagline }}</h4>
                    </div>
                    <div class="rounded-lg p-4 sm:p-6 col-span-4">
                      <ul>
                        <li v-for="(service, index) in services" :key="index">{{ service }}</li>
                      </ul>
                      <div class="flex justify-center items-center px-3">
                        <div class="flex items-center mt-2 pt-5">
                          <rating-bar :rating="this.rating" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button class="w-full bg-blue-500 hover:bg-blue-700 text-white rounded-md font-medium py-4 px-4 mt-6" @click.prevent="placeBooking">Request a consultation</button>

                <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 provider-info mt-6">
                  <h2>About</h2>
                  <p>{{ description }}</p>
                </div>

                <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 mt-6">
                  <h2 class="pb-8">Recomendations</h2>
                  <div class="item">
                    <div v-for="(recomendation, index) in recomendations" :key="index">
                      <div class="inline-block mb-6 rounded-full pr-5 h-16 line-height-username1 avatar">
                        <img :src="recomendation.image" class="rounded-full float-left h-full" />
                        <span class="ml-3">{{ recomendation.name }}</span>
                      </div>
                      <p class="pb-5">{{ recomendation.comment }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-span-4">
                <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 special-info mb-6">
                  <h2 class="pb-3">Special info</h2>
                  <ul>
                    <li v-for="(info, idx) in specialInfo" :key="idx">{{ info }}</li>
                  </ul>
                </div>

                <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 availability mt-2">
                  <h2 class="pb-3">Availability</h2>
                  <calendar :attributes="calanderProps" />
                </div>

                <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 2xl:col-span-2 availability mt-6">
                  <h2 class="mb-3">Contact</h2>
                  <textarea
                    class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-3xl transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Your message">
                  </textarea>

                  <div class="flex items-start mb-6 mt-4 mb-4">
                    <div class="flex items-center h-5">
                      <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4" required />
                    </div>
                    <div class="ml-3 text-sm w-full">
                      <label for="remember" class="font-medium text-gray-900 dark:text-gray-300 text-[16px]">Request for quotation</label>
                    </div>
                  </div>
                  <button class="bg-black hover:bg-black-700 text-white px-5 py-2 rounded-full font-normal flex ml-a mb-5">Send Message</button>
                </div>
                <p></p>
              </div>
            </div>
            <map-view style="height: 300px" :center="[lat, lng]" class="w-full map mb-12"></map-view>
          </main>
        </div>
      </div>
    </div>

    <!-- <div class="recomended-for-you mt-5 mb-10 bg-white">
      <div class="container mx-auto">
        <div class="pb-12 pt-12 top-heading">
          <h2>Recomended for you</h2>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 user-grid">
          <div
            v-for="(client, index) in clients"
            :key="index"
            class="w-full bg-white rounded-xl sahdow-lg overflow-hidden flex flex-col justify-start items-start item drop-shadow-md">
            <div class="w-full"><img :src="client.image" alt="photo" class="object-center object-cover h-auto w-full" /></div>
            <div class="flex justify-center items-center px-3">
              <rating-bar :rating="client.rating" />
            </div>
            <div class="py-8 sm:py-6 px-3">
              <p class="name text-xl mb-2 font-normal">{{ client.name }}</p>
              <p class="job-title text-base font-normal">{{ client.tagline }}</p>
              <p class="desc pt-2">{{ client.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <section class="top-consultants bg-white recomended-for-you mb-12">
      <div class="container mx-auto">
        <div class="text-left pb-7 pt-7 top-heading">
          <h1>Recomended for you</h1>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 user-grid">
          <!-- <a
            data-v-887569aa=""
            href="/consultant/public/f0297e54-9f72-418e-9854-4cf98499ad44"
            class="sahdow-lg item flex w-full flex-col items-start justify-start overflow-hidden rounded-xl bg-white drop-shadow-md"
            ><div data-v-887569aa="" class="w-full">
              <img data-v-887569aa="" src="https://www.allthetests.com/quiz22/picture/pic_1171831236_1.png" alt="photo" class="h-auto w-full object-cover object-center" />
            </div>
          </a> -->

          <div
            v-for="(client, index) in clients"
            :key="index"
            class="w-full bg-white rounded-xl sahdow-lg overflow-hidden flex flex-col justify-start items-start item drop-shadow-md">
            <div class="w-full">
              <img :src="client.image" alt="photo" class="h-auto w-full object-cover object-center" />
            </div>
            <div class="flex justify-center items-center px-3">
              <rating-bar :rating="client.rating" />
            </div>
            <div class="h-full w-full py-8 px-3 sm:py-6">
              <p class="name mb-2 text-xl font-normal">{{ client.name }}</p>
              <p class="job-title text-base font-normal">{{ client.tagline }}</p>
              <p class="desc pt-2 description">{{ client.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import RatingBar from '@/components/RatingBar.vue';
import MapView from '@/components/MapView.vue';
import consultant_service from '@/services/consultant_service';

export default {
  name: 'consultant_public',
  components: {
    'map-view': MapView,
    'rating-bar': RatingBar,
  },
  data() {
    return {
      consultant: null,
      calanderProps: [
        { dates: '2022-03-10', highlight: true },
        { dates: '2022-03-12', highlight: true },
        { dates: '2022-03-16', highlight: true },
      ],
      lat: 0,
      lng: 0,
      image: null,
      name: null,
      tagline: null,
      description: null,
      rating: 4,
      services: [],
      specialInfo: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tristique risus ante', 'Duis non eros mollis, pellentesque magna at, pharetra enim.'],
      recomendations: [
        {
          image: 'https://randomuser.me/api/portraits/women/34.jpg',
          name: 'Kate Horwitz',
          comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
        },
        {
          image: 'https://randomuser.me/api/portraits/women/24.jpg',
          name: 'Katherine Banks',
          comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.',
        },
      ],
      clients: [
        {
          image: 'https://randomuser.me/api/portraits/men/91.jpg',
          name: 'Siegfried Sinn',
          tagline: 'Tax Consultant',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          rating: 4,
        },
        {
          image: 'https://randomuser.me/api/portraits/men/92.jpg',
          name: 'Siegfried Sinn',
          tagline: 'Tax Consultant',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          rating: 5,
        },
        {
          image: 'https://randomuser.me/api/portraits/men/93.jpg',
          name: 'Larry Page',
          tagline: 'CEO star bucks',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          rating: 3,
        },
        {
          image: 'https://randomuser.me/api/portraits/men/94.jpg',
          name: 'Siegfried Sinn',
          tagline: 'Tax Consultant',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
          rating: 4,
        },
      ],
    };
  },
  methods: {
    placeBooking() {
      this.$router.push({ name: 'booking', params: { id: this.$route.params.id, consultant: this.consultant } });
    },
    loadConsultant(id) {
      consultant_service
        .getConsultant(id)
        .then(({ data }) => {
          this.consultant = data.consultant;
          this.name = data.consultant.name;
          this.tagline = data.consultant.tagline;
          this.description = data.consultant.description;
          this.rating = data.consultant.rating;
          this.services = data.consultant.services;
          this.specialInfo = data.consultant.specialInfo || [
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tristique risus ante',
            'Duis non eros mollis, pellentesque magna at, pharetra enim.',
          ];
          this.lat = data.consultant.latitude;
          this.lng = data.consultant.longitude;
          //this.recomendations = response.data.consultant.recomendations;
          //this.clients = response.data.consultant.clients;
          return this.$s3.getDpUrl(this.consultant.id);
        })
        .then((url) => (this.image = url))
        .catch(({ response }) => {
          this.$msg.error({ title: 'Unable to load consultant', message: response.data.error });
        });
    },
  },
  mounted() {
    this.loadConsultant(this.$route.params.id);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
